import { PageHeader } from '../layout/PageHeader';

const Privacy = () => {
  return (
    <div className='container-fluid' style={{ margin: 0, padding: 0 }}>
      <PageHeader page={'Privacy'} icon={'fa-solid fa-lock'} />
      <div className='privacy-main'>
        <div id='privacy-content' className='container'>
          <div className='overview'>
            <h2>Privacy Policy</h2>
          </div>

          <div className='medium-12 columns'>
            <p>
              This Privacy Policy (henceforth &quot;Policy&quot;) is provided by
              and between Martin Dwyer and MD Web Technologies, jointly and
              severally, your recognition of which is made effective as of the
              date of your use of the website located at www.mdbytes.com.
            </p>
            <p>
              The terms “We”, “Us” or “Our” shall refer to MD Web Technologies
              jointly with proprietor Martin Dwyer. The term &quot;this
              Site&quot; shall refer to the website located at www.mdbytes.com,
              along with all the pages therein. The terms “You”, “Your”, “User”
              or “Customer” shall refer to any individual or entity who uses
              this Site.
            </p>
            <p>
              This statement explains our policies and practices regarding the
              use and disclosure of Your personal information by Us. We review
              and update this Policy from time to time as needed without notice.
              Therefore, You should review the terms of this policy periodically
              to make sure that You are aware of how We collect and use personal
              information.
            </p>
            <p>
              This policy is only a portion of Our{' '}
              <a href='/terms'>Universal Terms of Service Agreement</a>. By
              using this Site, You consent to both this Policy and the
              <a href='/terms'>Universal Terms of Service Agreement</a>
              ..
            </p>
            <ol>
              <li>
                <h4>Personal Information.</h4>
                <p>
                  You have complete control over Your personal information. In
                  general, You can visit our web site without providing us with
                  any personal information. However, there are instances where
                  we must have Your personal information in order for us to
                  grant You an access to our protected and secured sites. This
                  information may include registration data (your name, address,
                  email address, phone number, title, etc.), information request
                  data and response data (User Information). When You receive
                  Your confirmation email or when You receive any email from the
                  list server, You will be given instructions on how to remove
                  Yourself from the list.
                </p>
              </li>
              <li>
                <h4>Use of User Information.</h4>
                <p>
                  We intend to use such information for purposes of supporting
                  Your relationship with Us. This User Information may be
                  retained by Us to verify compliance with Our{' '}
                  <a href='/terms'>Universal Terms of Service Agreement</a>, to
                  keep track of the domains from which people visit us, to
                  create a User profile, or to contact You either electronically
                  or otherwise.
                </p>
                <p>
                  If You choose to opt out of ongoing email communication,
                  please let us know through the{' '}
                  <a href='/contact'>Contact Page</a> and we will not use that
                  information for such purpose.
                </p>
                <p>
                  Please do not enter User information if You are less than 18
                  years of age.
                </p>
              </li>
              <li>
                <h4>Disclosure of User Information.</h4>
                <p>
                  We do not sell, trade or transfer User Information to third
                  parties. However, we may share User Information with Our
                  business partners for marketing, advertising or
                  product/service offering purposes.
                </p>
                <p>
                  For example, we provide User Information to our service
                  providers for direct emailing of our newsletters, online
                  surveys or notifications on regarding offerings to our users.
                </p>
                <p>
                  We also disclose User Information if: (1) we have Your
                  consent; (2) we need to share it in order to provide You with
                  the products and/or services You requested; or (3) we are
                  responding to a court order to do so.
                </p>
                <p>
                  You may separately agree to provide Your personal information
                  to third parties in the process of using our services. If You
                  agree to provide such information to these third parties, then
                  Your personal information will be subject to their privacy
                  policies.
                </p>
              </li>
              <li>
                <h4>Accuracy and Security.</h4>
                <p>
                  The accuracy and security of the User Information is important
                  to Us. Currently, You may review and request updates to Your
                  User Information retained by contacting us through the{' '}
                  <a href='/contact'>Contact Page</a>. If You contact us to
                  correct Your User Information, we will attempt to correct such
                  inaccuracies in a timely manner.
                </p>
                <p>
                  We are concerned with the security of Your User Information
                  and are committed to taking reasonable steps to protect it
                  from unauthorized access and use. To that end, we put in place
                  the appropriate physical, electronic and managerial policies
                  and procedures to secure Your personal User Information. We
                  also continue to implement procedures to maintain accurate,
                  complete and current User Information.
                </p>
              </li>
              <li>
                <h4>Usernames and Passwords.</h4>
                <p>
                  Access to certain content on our web site may be allowed under
                  a written agreement and will require a username and/or
                  password. In some cases, failure to provide personal
                  information may prevent You from accessing certain parts of
                  Our web site(s) containing certain confidential information,
                  products, services, or promotional offers.
                </p>
              </li>
              <li>
                <h4>Cookies.</h4>
                <p>
                  We use cookies. A cookie is a small data file that a web site
                  can transfer to a visitor&apos;s hard drive to keep records of
                  the visits to such site. A cookie contains information such as
                  Your username and password that helps us recognize the pages
                  You have visited and improve future visits, but the only
                  personal information a cookie can contain is the information
                  that You provide Yourself. A cookie cannot read data off of
                  Your hard drive or read cookie files created by other sites.
                  Information stored in cookies may be encrypted, however, we do
                  not store Your credit card number in cookies. If You prefer
                  not to accept a cookie, You can set Your web browser to warn
                  You before accepting cookies or You can refuse all cookies by
                  turning them off in Your web browser. However, access to some
                  of our secured web site(s) may require the use of cookies,
                  which You must enable only cookies that get sent back to the
                  originating server. Otherwise, You can still access most of
                  the features on our web site even without accepting a cookie.
                </p>
              </li>

              <li>
                <h4>General.</h4>
                <p>
                  If You have questions regarding our Privacy Statement, please
                  contact Us via our <a href='/contact'>Contact Page</a> .
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
